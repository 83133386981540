import React from 'react';
import File from '../../../extras/File';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Economia = () => {

    const navigate = useNavigate();
    
    const images = [
        { id: 1, name: 'Resultados oportunos de los censos económicos 2019', image: 'publicaciones/documentos_analisis/economia/ANALISIS-DE-Resultados-Oportunos-de-los-Censos-Economicos-2019.png', file: 'publicaciones/documentos_analisis/economia/ANALISIS-DE-Resultados-Oportunos-de-los-Censos-Economicos-2019.pdf', type: 'PDF' },
        { id: 2, name: 'Censos Económicos 2019 Resultados Generales Zacatecas', image: 'publicaciones/documentos_analisis/economia/CE2019_Zac.png', file: 'publicaciones/documentos_analisis/economia/CE2019_Zac.pdf', type: 'PDF' },
        { id: 3, name: 'Empleos registrados en el IMSS Junio 2023', image: 'publicaciones/documentos_analisis/economia/empleos_imss_06-2023.png', file: 'publicaciones/documentos_analisis/economia/empleos_imss_06-2023.pdf', type: 'PDF' },
        { id: 4, name: 'ENOE Principales Resultados 1er Trimestre 2023', image: 'publicaciones/documentos_analisis/economia/Ocupacion_y_Empleo_al_1er_trim_2023.png', file: 'publicaciones/documentos_analisis/economia/Ocupacion_y_Empleo_al_1er_trim_2023.pdf', type: 'PDF' },
        { id: 5, name: 'Empleos registrados en el IMSS Julio 2023', image: 'publicaciones/documentos_analisis/economia/Empleos_registrados_en_el_IMSS_Julio_2023.png', file: 'publicaciones/documentos_analisis/economia/Empleos_registrados_en_el_IMSS_Julio_2023.pdf', type: 'PDF' },
        { id: 6, name: 'Ingresos por remesas Segundo Trimestre 2023', image: 'publicaciones/documentos_analisis/economia/remesas_2trim-2023.png', file: 'publicaciones/documentos_analisis/economia/remesas_2trim-2023.pdf', type: 'PDF' },
        { id: 7, name: 'Empleos registrados en el IMSS Agosto 2023', image: 'publicaciones/documentos_analisis/economia/empleos_imss_08-2023.png', file: 'publicaciones/documentos_analisis/economia/empleos_imss_08-2023.pdf', type: 'PPT' },
        { id: 8, name: 'Empleos registrados en el IMSS Diciembre 2023', image: 'publicaciones/documentos_analisis/economia/empleos-imss_12-2023.png', file: 'publicaciones/documentos_analisis/economia/empleos-imss_12-2023.pdf', type: 'PDF' },
        { id: 9, name: 'Ingreso por remesas Tercer Trimestre 2023', image: 'publicaciones/documentos_analisis/economia/remesas_3trim-2023.png', file: 'publicaciones/documentos_analisis/economia/remesas_3trim-2023.pdf', type: 'PDF' },
        { id: 10, name: 'Empleos Registrados en el IMSS Enero 2024', image: 'publicaciones/documentos_analisis/economia/empleos-imss-01-2024.png', file: 'publicaciones/documentos_analisis/economia/empleos-imss-01-2024.pdf', type: 'PDF' },
        { id: 11, name: 'Principales resultados Trimestrales 2019-2023 ENOE', image: 'publicaciones/documentos_analisis/economia/Ocupacion_Empleo19_23.png', file: 'publicaciones/documentos_analisis/economia/Ocupacion_Empleo19_23.pdf', type: 'PDF' },
        { id: 12, name: 'Empleos Registrados en el IMSS Abril 2024', image: 'publicaciones/documentos_analisis/economia/empleos-imss_04-2024.png', file: 'publicaciones/documentos_analisis/economia/empleos-imss_04-2024.pdf', type: 'PDF' },
        { id: 13, name: 'Empleos Registrados en el IMSS Mayo 2024', image: 'publicaciones/documentos_analisis/economia/empleos-imss_05-2024.png', file: 'publicaciones/documentos_analisis/economia/empleos-imss_05-2024.pdf', type: 'PDF' },
        { id: 14, name: 'Empleos Registrados en el IMSS Agosto 2024', image: 'publicaciones/documentos_analisis/economia/empleos_imss_08_2024.png', file: 'publicaciones/documentos_analisis/economia/empleos_imss_08_2024.pdf', type: 'PDF' },
        { id: 15, name: 'Empleos Registrados en el IMSS Septiembre 2024', image: 'publicaciones/documentos_analisis/economia/empleos_imss_09_2024.png', file: 'publicaciones/documentos_analisis/economia/empleos_imss_09_2024.pdf', type: 'PDF' },
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Economía</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default Economia;