import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Barras from './Barras';
import Exportacion from './Exportacion';

const Pea = ({ route, nota = null, fuente = null }) => {
    const [key, setKey] = useState('total');

    useEffect(() => {
        console.log(route);
    }, []);

    return (
        <div className='container'>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3">
                <Tab eventKey="total" title="Total">
                    <div className='row justify-content-md-center'>
                        <Barras route={route} />
                    </div>
                </Tab>
                {route == 'exportacion' &&
                    <Tab eventKey="exportacion" title="Exportación">
                        <div className='row justify-content-md-center'>
                            <Exportacion route={route} />
                        </div>
                    </Tab>
                }
            </Tabs>
            <div className='row'>
                {fuente &&
                    <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                        <p><strong>Fuente: </strong><i>{fuente}</i></p>
                    </div>
                }
                {nota &&
                    <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                        <p><strong>Nota: </strong><i>{nota}</i></p>
                    </div>
                }
            </div>
        </div>
    );
}
export default Pea;