import React from 'react';
import { Link } from 'react-router-dom';

const PanoramaSocioDemograficoMunicipio2020 = () => {

    const styleEnabled = { background: '#990B0C', color: '#FFFFFF', fontSize: '14', fontWeight: 'bold', cursor: 'pointer' };
    const route = 'publicaciones/panorama-sociodemografico-municipal-2020/';

    const municipios = [
        { id: 1, nombre: "APOZOL", file: "001-APOZOL.pdf" },
        { id: 2, nombre: "APULCO", file: "002-APULCO.pdf" },
        { id: 3, nombre: "ATOLINGA", file: "003-ATOLINGA.pdf" },
        { id: 4, nombre: "BENITO-JUAREZ", file: "004-BENITO-JUAREZ.pdf" },
        { id: 5, nombre: "CALERA", file: "005-CALERA.pdf" },
        { id: 6, nombre: "CANITAS-DE-FELIPE-PESCADOR", file: "006-CANITAS-DE-FELIPE-PESCADOR.pdf" },
        { id: 7, nombre: "CONCEPCION-DEL-ORO", file: "007-CONCEPCION-DEL-ORO.pdf" },
        { id: 8, nombre: "CUAUHTEMOC", file: "008-CUAUHTEMOC.pdf" },
        { id: 9, nombre: "CHALCHIHUITES", file: "009-CHALCHIHUITES.pdf" },
        { id: 10, nombre: "FRESNILLO", file: "010-FRESNILLO.pdf" },
        { id: 11, nombre: "TRINIDAD-GARCIA-DE-LA-CADENA", file: "011-TRINIDAD-GARCIA-DE-LA-CADENA.pdf" },
        { id: 12, nombre: "GENARO-CODINA", file: "012-GENARO-CODINA.pdf" },
        { id: 13, nombre: "GENERAL-ENRIQUE-ESTRADA", file: "013-GENERAL-ENRIQUE-ESTRADA.pdf" },
        { id: 14, nombre: "GENERAL-FCO-R-MURGUIA", file: "014-GENERAL-FCO-R-MURGUIA.pdf" },
        { id: 15, nombre: "EL-PLATEADO-DE-JOAQUIN-AMARO", file: "015-EL-PLATEADO-DE-JOAQUIN-AMARO.pdf" },
        { id: 16, nombre: "GENERAL-PANFILO-NATERA", file: "016-GENERAL-PANFILO-NATERA.pdf" },
        { id: 17, nombre: "GUADALUPE", file: "017-GUADALUPE.pdf" },
        { id: 18, nombre: "HUANUSCO", file: "018-HUANUSCO.pdf" },
        { id: 19, nombre: "JALPA", file: "019-JALPA.pdf" },
        { id: 20, nombre: "JEREZ", file: "020-JEREZ.pdf" },
        { id: 21, nombre: "JIMENEZ-DEL-TEUL", file: "021-JIMENEZ-DEL-TEUL.pdf" },
        { id: 22, nombre: "JUAN-ALDAMA", file: "022-JUAN-ALDAMA.pdf" },
        { id: 23, nombre: "JUCHIPILA", file: "023-JUCHIPILA.pdf" },
        { id: 24, nombre: "LORETO", file: "024-LORETO.pdf" },
        { id: 25, nombre: "LUIS-MOYA", file: "025-LUIS-MOYA.pdf" },
        { id: 26, nombre: "MAZAPIL", file: "026-MAZAPIL.pdf" },
        { id: 27, nombre: "MELCHOR-OCAMPO", file: "027-MELCHOR-OCAMPO.pdf" },
        { id: 28, nombre: "MEZQUITAL-DEL-ORO", file: "028-MEZQUITAL-DEL-ORO.pdf" },
        { id: 29, nombre: "MIGUEL-AUZA", file: "029-MIGUEL-AUZA.pdf" },
        { id: 30, nombre: "MOMAX", file: "030-MOMAX.pdf" },
        { id: 31, nombre: "MONTE-ESCOBEDO", file: "031-MONTE-ESCOBEDO.pdf" },
        { id: 32, nombre: "MORELOS", file: "032-MORELOS.pdf" },
        { id: 33, nombre: "MOYAHUA", file: "033-MOYAHUA.pdf" },
        { id: 34, nombre: "NOCHISTLAN-DE-MEJIA", file: "034-NOCHISTLAN-DE-MEJIA.pdf" },
        { id: 35, nombre: "NORIA-DE-ANGELES", file: "035-NORIA-DE-ANGELES.pdf" },
        { id: 36, nombre: "OJOCALIENTE", file: "036-OJOCALIENTE.pdf" },
        { id: 37, nombre: "PANUCO", file: "037-PANUCO.pdf" },
        { id: 38, nombre: "PINOS", file: "038-PINOS.pdf" },
        { id: 39, nombre: "RIO-GRANDE", file: "039-RIO-GRANDE.pdf" },
        { id: 40, nombre: "SAIN-ALTO", file: "040-SAIN-ALTO.pdf" },
        { id: 41, nombre: "EL-SALVADOR", file: "041-EL-SALVADOR.pdf" },
        { id: 42, nombre: "SOMBRERETE", file: "042-SOMBRERETE.pdf" },
        { id: 43, nombre: "SUSTICACAN", file: "043-SUSTICACAN.pdf" },
        { id: 44, nombre: "TABASCO", file: "044-TABASCO.pdf" },
        { id: 45, nombre: "TEPECHITLAN", file: "045-TEPECHITLAN.pdf" },
        { id: 46, nombre: "TEPETONGO", file: "046-TEPETONGO.pdf" },
        { id: 47, nombre: "TEUL-DE-GONZALEZ-ORTEGA", file: "047-TEUL-DE-GONZALEZ-ORTEGA.pdf" },
        { id: 48, nombre: "TLALTENANGO-DE-SANCHEZ-ROMAN", file: "048-TLALTENANGO-DE-SANCHEZ-ROMAN.pdf" },
        { id: 49, nombre: "VALPARAISO", file: "049-VALPARAISO.pdf" },
        { id: 50, nombre: "VETAGRANDE", file: "050-VETAGRANDE.pdf" },
        { id: 51, nombre: "VILLA-DE-COS", file: "051-VILLA-DE-COS.pdf" },
        { id: 52, nombre: "VILLA-GARCIA", file: "052-VILLA-GARCIA.pdf" },
        { id: 53, nombre: "VILLA-GONZALEZ-ORTEGA", file: "053-VILLA-GONZALEZ-ORTEGA.pdf" },
        { id: 54, nombre: "VILLA-HIDALGO", file: "054-VILLA-HIDALGO.pdf" },
        { id: 55, nombre: "VILLANUEVA", file: "055-VILLANUEVA.pdf" },
        { id: 56, nombre: "ZACATECAS", file: "056-ZACATECAS.pdf" },
        { id: 57, nombre: "TRANCOSO", file: "057-TRANCOSO.pdf" },
        { id: 58, nombre: "SANTA-MARIA-DE-LA-PAZ", file: "058-SANTA-MARIA-DE-LA-PAZ.pdf" }
    ];

    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Panorama Sociodemografico por Municipio 2020</h2>
                </div>
            </div>
            <hr />
            {municipios.map((mun) => {
                return (
                    <React.Fragment key={mun.id}>
                        <div className='row'>
                            <div className='col-md-11'>
                                <h3>
                                    <Link to={`/pdf/${route}${mun.file}`} data-tip data-for={`${route}${mun.file}`} style={{ color: '#000000', textDecoration: 'none' }}>
                                        {mun.nombre}
                                    </Link>
                                </h3>
                            </div>
                            <div className='col-md-1'>
                                <div className='col-md-6'></div>
                                <div className='col-md-6 text-center' style={styleEnabled}>
                                    {mun.file &&
                                        <Link to={`/pdf/${route}${mun.file}`} data-tip data-for={`${route}${mun.file}`} style={{ color: '#FFF', textDecoration: 'none' }}>
                                            <span><small> PDF</small></span>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>
                )
            })}
        </div>
    );
}

export default PanoramaSocioDemograficoMunicipio2020;