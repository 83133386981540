import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../config/clientAxios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import Cargando from '../../../extras/Cargando';
import { Tabs, Tab } from 'react-bootstrap';
import {SimpleCurrencyFormat} from '../../../extras/CurrencyFormat';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

const Remesas = () => {
    const [statusA, setStatusA] = useState(undefined);
    const [statusT, setStatusT] = useState(undefined);
    const [anual, setAnual] = useState({ "datasets": [], "labels": [] });
    const [trimestral, setTrimestal] = useState({ "datasets": [], "labels": [] });
    const optionsBar = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `Remesas Anuales: ${tooltipItem.formattedValue}`;
                    }
                }
            },
        }
    };
    const optionsLine = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
    };
    const [key, setKey] = useState('anual');
    useEffect(() => {
        const getAnual = async () => {
            await clienteAxios.get(`cieg/migracion/remesas/anual`, { timeout: 30000 })
                .then(response => {
                    setStatusA(true);
                    setAnual(response.data.datasets);
                    console.log(response.data.datasets);
                }).catch(error => {
                    setStatusT(false);
                    console.error(error);
                });
        }
        const getTrimestal = async () => {
            await clienteAxios.get(`cieg/migracion/remesas/trimestral`, { timeout: 30000 })
                .then(response => {
                    setStatusT(true);
                    setTrimestal(response.data.datasets);
                }).catch(error => {
                    setStatusT(false);
                    console.error(error);
                });
        }
        getAnual();
        getTrimestal();
    }, []);
    return (
        <div className='container'>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3">
                <Tab eventKey="anual" title="Anual">
                    <div className='row justify-content-md-center'>
                        {statusA === true ? <Bar data={anual} options={optionsBar} /> : <Cargando />}
                    </div>
                </Tab>
                <Tab eventKey="trimestral" title="Trimestal">
                    <div className='row justify-content-md-center'>
                        {statusT === true ? <Line options={optionsLine} data={trimestral} /> : <Cargando />}
                    </div>
                </Tab>
            </Tabs>
            <div className='row'>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Fuente: </strong><i>{"Banco Nacional de México"}</i></p>
                </div>
            </div>
        </div>
    );
}
export default Remesas;