import React from 'react';
import Folder from '../../../extras/Folder';

const Boletin = () => {

    const folders = [
        { id: 1, nombre: 'INDICADOR TRIMESTRAL DE LA ACTIVIDAD ECONÓMICA ESTATAL 2021', route: '/publicaciones/boletines_inegi/ITAEE_2021' },
        { id: 2, nombre: 'INDICADOR TRIMESTRAL DE LA ACTIVIDAD ECONÓMICA ESTATAL 2022', route: '/publicaciones/boletines_inegi/ITAEE_2022' },
        { id: 3, nombre: 'INDICADOR TRIMESTRAL DE LA ACTIVIDAD ECONÓMICA ESTATAL 2023', route: '/publicaciones/boletines_inegi/ITAEE_2023' },
        { id: 4, nombre: 'ENCUESTA NACIONAL DE OCUPACIÓN Y EMPLEO 2021', route: '/publicaciones/boletines_inegi/ENOE_ZAC_2021' },
        { id: 5, nombre: 'ENCUESTA NACIONAL DE OCUPACIÓN Y EMPLEO 2022', route: '/publicaciones/boletines_inegi/ENOE_ZAC_2022' },
        { id: 6, nombre: 'ENCUESTA NACIONAL DE OCUPACIÓN Y EMPLEO 2023', route: '/publicaciones/boletines_inegi/ENOE_ZAC_2023' },
        { id: 7, nombre: 'ESTADÍSTICAS A PROPÓSITO', route: '/publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO' },
        { id: 8, nombre: 'EXPORTACIONES POR ENTIDAD FEDERATIVA 2021', route: '/publicaciones/boletines_inegi/ETEF2021' },
        { id: 9, nombre: 'EXPORTACIONES POR ENTIDAD FEDERATIVA 2022', route: '/publicaciones/boletines_inegi/ETEF2022' },
        { id: 10, nombre: 'EXPORTACIONES POR ENTIDAD FEDERATIVA 2023', route: '/publicaciones/boletines_inegi/ETEF2023' },
        { id: 11, nombre: 'INDICADOR MENSUAL DE LA ACTIVIDAD INDUSTRIAL POR ENTIDAD FEDERATIVA', route: '/publicaciones/boletines_inegi/IMAIEF' },
        { id: 12, nombre: 'PRODUCTO INTERNO BRUTO', route: '/publicaciones/boletines_inegi/PIB' },

        { id: 13, nombre: 'ENCUESTA NACIONAL DE OCUPACIÓN Y EMPLEO 2024', route: '/publicaciones/boletines_inegi/ENOE_ZAC_2024' },
        { id: 14, nombre: 'INDICADOR TRIMESTRAL DE LA ACTIVIDAD ECONÓMICA ESTATAL 2024', route: '/publicaciones/boletines_inegi/ITAEE_2024' },
    ];

    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Boletines INEGI</h2>
                </div>
            </div>
            <hr />
            <br />
            <div className='row'>
                {folders.map((f) => {
                    return (
                        <Folder item={f} />
                    );
                })}
            </div>
            <br /><br /><br /><br /><br /><br /> <br /><br /><br /><br /><br /><br /> <br />
        </div>
    );
}
export default Boletin;