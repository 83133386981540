import React, { useEffect } from 'react';

const Contador = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.powr.io/powr.js?platform=web";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <React.Fragment>
            <div className="powr-hit-counter" id="636bbb6c_1729013679"></div>
        </React.Fragment>
    );
}

export default Contador;