import React from 'react';
import File from '../../../extras/File';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ESTADISTICAS = () => {

    const navigate = useNavigate();

    const images = [
        { id: 1, name: '10 DE MAYO', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/10_DE_MAYO.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/10_DE_MAYO.pdf', type: 'PDF' },
        { id: 2, name: 'CONSULTORIAS', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/CONSULTORIAS.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/CONSULTORIAS.pdf', type: 'PDF' },
        { id: 3, name: 'DÍA DEL TRABAJO', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/DIA_DEL_TRABAJO.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/DIA_DEL_TRABAJO.pdf', type: 'PDF' },
        { id: 4, name: 'DÍA MUNDIAL DE LA POBLACIÓN', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/DIA_MUNDIAL_DE_LA_POBLACION.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/DIA_MUNDIAL_DE_LA_POBLACION.pdf', type: 'PDF' },
        { id: 5, name: 'DÍA MUNDIAL DEL INTERNET', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/DIA_MUNDIAL_DEL_INTERNET.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/DIA_MUNDIAL_DEL_INTERNET.pdf', type: 'PDF' },
        { id: 6, name: 'DIVERSIDAD SEXUAL Y DE GÉNERO', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/Diversidad_Sexual_y_de_Genero.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/Diversidad_Sexual_y_de_Genero.pdf', type: 'PDF' },
        { id: 7, name: 'HOMICIDIOS 2021', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/HOMICIDIOS_2021.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/HOMICIDIOS_2021.pdf', type: 'PDF' },
        { id: 8, name: 'PIBE ZACATECAS 2020', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/PIBE_ZACATECAS_2020.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/PIBE_ZACATECAS_2020.pdf', type: 'PDF' },
        { id: 9, name: 'PREVENCIÓN DEL SUICIDIO', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/Prevencion_del_Suicidio.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/Prevencion_del_Suicidio.pdf', type: 'PDF' },
        { id: 10, name: 'PROTECCIÓN CIVIL', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/Proteccion_Civil.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/Proteccion_Civil.pdf', type: 'PDF' },
        { id: 11, name: 'TRABAJADORAS DOMESTICAS', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/TRABAJADORAS_DOMESTICAS.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/TRABAJADORAS_DOMESTICAS.pdf', type: 'PDF' },
        { id: 12, name: 'TRABAJO INFANTIL', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/TRABAJO_INFANTIL.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/TRABAJO_INFANTIL.pdf', type: 'PDF' },
        { id: 13, name: 'Día Mundial del Internet', image: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/EAP_DMInternet.png', file: 'publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/EAP_DMInternet.pdf', type: 'PDF' },
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>ESTADÍSTICAS A PROPÓSITO</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id} />
                    );
                })}
            </div>
            <br /><br /><br /><br /><br />
        </div>
    );
}

export default ESTADISTICAS;