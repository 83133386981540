export const PrimaryColor = (type) => {
    if (type == 'PDF') {
        return '#990B0C';
    } else if (type == 'XLS' || type == 'CSV') {
        return '#0B9923';
    } else if (type == 'DOC' || type == 'ODT' || type == 'DOCX') {
        return '#367EC1';
    } else if (type == 'PPT') {
        return '#EBEB13';
    } else {
        return '#65655F';
    }
}

export const SecondaryColor = (type) => {
    if (type == 'PDF') {
        return '#DD8788';
    } else if (type == 'XLS' || type == 'CSV') {
        return '#9EF6AD';
    } else if (type == 'DOC' || type == 'ODT' || type == 'DOCX') {
        return '#A1C9F0';
    } else if (type == 'PPT') {
        return '#EEEE94';
    } else {
        return '#A5A59C';
    }
}